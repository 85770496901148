.custum-file-upload {
    height: 200px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    gap: 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 2px dashed #cacaca;
    background-color: rgba(255, 255, 255, 1);
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 48px 35px -48px rgba(0,0,0,0.1);
  }
  
  .custum-file-upload .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custum-file-upload .icon svg {
    height: 80px;
    fill: rgba(75, 85, 99, 1);
  }
  
  .custum-file-upload .text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custum-file-upload .text span {
    font-weight: 400;
    color: rgba(75, 85, 99, 1);
  }
  
  .custum-file-upload input {
    display: none;
  }

  .checkbox {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .reemplazar-text{
    font-weight: bold;
    padding-right: 5px;
    font-size: 15px
  }

  .checkbox input[type="checkbox"] {
    display: none;
    visibility: hidden;
  }
  
  .checkbox .cbx {
    -webkit-perspective: 20;
    perspective: 20;
    display: inline-block;
    border: 2px solid #2f00ff;
    background: #e8e8eb;
    border-radius: 4px;
    transform: translate3d(0, 0, 0);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .checkbox .cbx:hover {
    border-color: #0b76ef;
  }
  
  .checkbox .flip {
    display: block;
    transition: all 0.4s ease;
    transform-style: preserve-3d;
    position: relative;
    width: 20px;
    height: 20px;
  }
  
  .checkbox input[type="checkbox"]:checked + .cbx {
    border-color: #0b76ef;
  }
  
  .checkbox input[type="checkbox"]:checked + .cbx .flip {
    transform: rotateY(180deg);
  }
  
  .checkbox .front,
  .checkbox .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }
  
  .checkbox .front {
    background: #fff;
    z-index: 1;
  }
  
  .checkbox .back {
    transform: rotateY(180deg);
    background: #0b76ef;
    text-align: center;
    color: #fff;
    line-height: 20px;
    box-shadow: 0 0 0 1px #0b76ef;
  }
  
  .checkbox .back svg {
    margin-top: 3px;
    fill: none;
  }
  
  .checkbox .back svg path {
    stroke: #fff;
    stroke-width: 2.5;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  