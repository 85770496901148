h1,h2,h3,h4,h5,h6{
  margin-top:1rem;
  margin-bottom:1rem;
  font-family: 'Heebo',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-label,.custom-control-label{
  font-weight: bold;
}

.navbar{
  background-image: url('./Imagenes/banner_ventana.jpg');
}

.navbar-dark .navbar-nav .nav-link{
  color:white;
}

#logo{
  background-image:url('./Imagenes/banner_logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  /*position: absolute;*/
  top:0;
  right:0;
  width:100%;
  height:100%;
}

/* poner todos los elementos del navbar en frente del logo*/
#logo ~ *{
  z-index:auto;
}

.navbar-brand {
  display: flex;
  align-items: center;
}
.navbar-brand>img {
  padding: 7px 14px;
}

#navbar-nav{
  /* variable que se va a setear mediante javascript */ 
  left:var(--logoHeight);
  position:relative;
  min-height: 52px; 
  max-width:calc(100% - var(--logoHeight));
}

.aclaracion{
  color:navy;
  font-size: 0.85rem;
}

.flex-break{
  flex-basis:100%;
  height:0;
}

.cursor-pointer{
  cursor:pointer;
}

.llenar-espacio{
  min-height: calc(100vh - var(--logoHeight) - .5rem);
}

/* PARA PONER LABEL A LA IZQUIERDA DEL CHECKBOX */
div.custom-check-right {
  padding-right: 24px;
  padding-left: 0;
  /*margin-left: 16px;*/
  margin-right: 0;
}
div.custom-check-right .custom-control-label::before,
div.custom-check-right .custom-control-label::after{
  right: -1.5rem;
  left: initial;
}

/* GRILLA */
table{
  max-width:100%;
}

table.clamp{
    table-layout: fixed;
}

table.clamp td:not(.control),table.clamp th{ 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

table.table > tbody > tr.selected{
  background-color: aliceblue;
}

table.middle-vertical-align th,table.middle-vertical-align td{
  vertical-align:middle;
}

table.tablaConSeleccion tr > th:first-child,table.tablaConSeleccion tr > td:first-child{
  width:2em;
}

/* GRILLA CARGA MASIVA */
table.tablaExcel{
   white-space: nowrap;
}

table.tablaExcel > thead >tr> th{
  min-width:15em;
}

table.tablaExcel > tbody > tr:first{
    background: aliceblue;
    font-weight: bold;
}

/* LOADER */ 

.loader-container{
  position:relative;
  left:50%;
  height:50px;
}

.loader-inner > div{
  background-color: blue !important;
}

/* TOTALES SUBITEMS */

dl.totalesSubitems{
    display:flex;
    flex-wrap: wrap;
}

dl.totalesSubitems > div{
  width:33.33%;
}

dl.totalesSubitems>div>dt,dl.totalesSubitems>div>dd{
  width:50%;
  display: inline-block;
}

/* PARA PANTALLA ARMADO ITEMS */
.controlArmadoItems{
  height:2.5rem;
}

.totalesArmadoItems >tr>th,.totalesArmadoItems >tr>td{
  padding-left:0;
  padding-right:0;
}


/* PARA PONER GRILLAS QUE CUBRAN TODA LA PANTALLA*/
.full-width{
 width: 100vw;
position: relative;
left: 50%;
right: 50%;
margin-left: -50vw;
margin-right: -50vw;
padding-left: 15px;
}

/* PARA PRESUPUESTO */

.valoresPresupuesto{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows:1fr 1fr 1fr 1fr 1fr;
}

/* TODO: tomar colores de bootstrap */
.canalRojo{
  color:#dc3545;
}
.canalNaranja{
  color:#d2932d;
}
.canalVerde{
  color:#29a875;
}

.loginForm .input-group *{
  border-width: 1px;
}



 /* Establece el ancho al 30% por defecto */
 .mi-div {
  width: 30%;
  margin: auto;
}

/* Utiliza una media query para cambiar el ancho al 60% cuando la pantalla es pequeña, como un celular */
@media screen and (max-width: 1000px) {
  .mi-div {
    width: 70%;
    margin: auto;
  }
}