@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
/*body {
  background: #edf5e1;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-shadow: 0.5px 0.5px 0 #cccccc;
}*/

.background {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 0.75s;
}

.containers {
  padding: 0px;
  width: 100%;
  margin: 0px 0 0 0;
  left: 0;
  top: 0;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-shadow: 0.5px 0.5px 0 #cccccc;
}


.nav {
  padding: 0px;
  width: 100%;
  margin: 50px auto auto auto;
  justify-content: center;
}


ul {
  list-style-type: none;
  width: 100%;
}

.lis span {
  padding: 30px;
  float: left;
  width: 180px;
  text-align: center;
  font-size: 15px;
  color: #edf5e1;
  text-decoration: none;
}

.lis span:hover .circle {
  position: relative;
  z-index: 1;
  top: -60px;
  left: 19px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  animation: illuminate 2s ease-out infinite;
  background: #fdc6be;
  color: #edf5e1;
  text-decoration: none;
  cursor: pointer;
}

.lis i {
  height: 80px;
}

li span:hover i {
  transform: translateY(-1px);
  color: #edf5e1;
  text-decoration: none;
}

li span .title {
  font-size: 13px;
}

li span:hover .title {
  transform: translateY(1px);
  font-size: 15px;
  transition: 0.5s;
}

li span.title:hover {
  transform: translateY(-1px);
}

.nav li:first-child span {
  background: #05386b !important;
}

.nav li:nth-child(2) span {
  background: #379683 !important;
}

.nav li:nth-child(3) span {
  background: #5cdb95 !important;
}

.nav li:nth-child(4) span {
  background: #9d9f89 !important;
}



.nav li:nth-child(5) span {
  background: #038bbb !important;
}

.nav li:nth-child(6) span {
  background: #fccb6f !important;
}

.nav li:nth-child(7) span {
  background: #e19f41 !important;
}

.nav li:nth-child(8) span {
  background: #8ee4af !important;
}




.nav li:first-child:hover ~ .background {
  background: #05386b;
}

.nav li:nth-child(2):hover ~ .background {
  background: #379683;
}

.nav li:nth-child(3):hover ~ .background {
  background: #5cdb95;
}

.nav li:nth-child(4):hover ~ .background {
  background: #9d9f89;
}


.nav li:nth-child(5):hover ~ .background {
  background: #038bbb;
}

.nav li:nth-child(6):hover ~ .background {
  background: #fccb6f;
}

.nav li:nth-child(7):hover ~ .background {
  background: #e19f41;
}

.nav li:nth-child(8):hover ~ .background {
  background: #8ee4af;
}




@keyframes illuminate {
  0% {
    transform: scale(0.7, 0.7);
    opacity: 0.7;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.15);
    opacity: 0;
  }
}


.head-text {
    position: relative;
 }
 .text-on-image {
   position: absolute;
   left: 5.5%;
   top: 6%;
 }
 .titulo {
    font-size: 350%;   
    font-family: "Gill Sans", sans-serif;
    font-weight: bolder;   
    text-shadow: 5px 5px 5px gray; 
 }
.btnadv {
    border-radius: 10px;    
    padding: 50px 10px;
    width: 200px;
}


.fit {
  position: absolute;
  top: 90px;
  left: 0px;
  padding: 0;
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
}

