.table-responsives {
	max-height: 100%;
	overflow-y: auto;
  }
  
  @media (max-width: 1200px) {
	.table-responsives {
	  max-height: 100%;
	}
  
	th {
	  font-size: 14px; /* Reduce font size for medium screens */
	}
  }
  
  @media (max-width: 992px) {
	.table-responsives {
	  max-height: 100%;
	}
  
	th {
	  font-size: 12px; /* Reduce font size for smaller screens */
	}
  
	.table-responsives th, .table-responsives td {
	  padding: 8px; /* Adjust padding */
	}
  }
  
  @media (max-width: 768px) {
	.table-responsives {
	  max-height: 100%;
	}
  
	th, td {
	  font-size: 10px;
	  padding: 5px;
	}
  
	/* Make the table scroll horizontally if it overflows */
	.table-responsives {
	  overflow-x: auto;
	}
  
	.table-responsives table {
	  width: 100%;
	  min-width: 600px; /* Ensure table has a minimum width */
	}
  }

  
@media (max-width: 576px) {
	.seguroTotal {
		margin-top: 10%;
	}

	.buttonInsertar {
		margin-left: 25%;
	}

	.buttonEliminar {
		margin-top: 0;
		margin-left: 25%;
	}

	.buttonInsertarFactura {
		margin-left: 25%;
	}

	.buttonEliminarFactura {

		margin-left: 25%;
	}
}

@media (min-width: 1200px) {
	.buttonInsertar {
		margin-top: 16%;
		margin-left: 15%;
	}

	.buttonEliminar {
		margin-top: 16%;
		margin-left: -10%;
	}

	.buttonInsertarFactura {
		margin-top: 25%;
		margin-left: 12%;
	}

	.buttonEliminarFactura {
		margin-top: 16%;
		margin-left: 15%;
	}
}

@media (min-width: 1400px) {
	.buttonInsertar {
		margin-top: 16%;
		margin-left: 15%;
	}

	.buttonEliminar {
		margin-top: 16%;
		margin-left: -10%;
	}
}

.cardPreguntas {
	max-width: 420px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	border-radius: 0.5rem;
	margin-bottom: 10px;
	background: rgb(255, 255, 255);
	border: 1px black solid;
	padding: 1rem;
	color: rgb(0, 0, 0);
	margin-top: 5px;
  }
  
  hr.solid {
	border-top: 3px solid #524d4d;
  }
